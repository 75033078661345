<template lang="pug">
  Section.cy-plan-name-section(
    required
    :class="{ 'invalid-section': object.$error }"
    :tooltip="$t('plan_settings.basic_settings.sections.price_plan_name.tooltip')"
    :title="$t('plan_settings.basic_settings.sections.price_plan_name.section_name')"
  )
    input.form-control(
      type="text"
      v-model="object.$model"
      :maxlength="60"
      :class="{ 'is-invalid': object.$error }"
      :placeholder="$t('plan_settings.basic_settings.sections.price_plan_name.input_placeholder')"
    )
</template>

<script>
  export default {
    props: {
      object: Object
    },

    components: {
      Section: () => import("@/pages/PriceManagement/PlanSettings/BasicSettings/Section")
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/price-management/basic-settings.sass"

  +styled-inputs
</style>
